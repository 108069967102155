import React from "react"
import { Link, navigate } from "gatsby"
import { isLoggedIn, logout, getUser } from "../../../services/auth"
import { ReactComponent as Logo } from '../../../../static/logo/logo_primary.svg'


export default function NavProfile() {
  return (
  <div className="row">
    <div className="col-sm-12">
      <nav className="navbar fixed-top navbar-light bg-light" style={{paddingLeft: '1rem', paddingRight: '1rem'}}>
        <Link className="navbar-brand" to="/"><Logo /></Link>
        {` `}
        <ul className="nav justify-content-right">
            <li className="nav-item">
                <Link className="nav-link link-secondary" to="/madrid">Madrid</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link link-secondary" to="/barcelona">Barcelona</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link link-secondary" to="/utopicus">Utopicus</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link link-secondary" to="/contacto">Contacto</Link>
                {` `}
                {isLoggedIn() ? (
                <a
                className="nav-link link-secondary"
                href="/"
                onClick={event => {
                    event.preventDefault()
                    logout(() => navigate(`/app/login`))
                }}
                >
                | Salir
                </a>
                ) : null}
            </li>
            <li className="nav-item text-primary">{getUser().name}</li>
        </ul>
      </nav>
    </div>
  </div>
  )
}