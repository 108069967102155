import React from 'react'
import { Link } from "gatsby"

export default function Stock(props) {
  const { links } = props
  return (
    <div className="tab-pane">
        <div className="container overflow-hidden p-5">
            <div className="row align-items-stretch g-2">
                <div className="col">
                        <Link 
                        to={links.stockStocks}
                        target='_blank'
                        className="text-decoration-none"
                        >
                        <div className="card p-2 card-h">
                            <div className="card-body">
                                <h2 className='text-center p-2'>
                                    <span className="badge badge-profile bg-primary rounded-circle">
                                    <i class="bi bi-list-check"></i>
                                    </span>
                                </h2>
                                <h5 className="card-title text-center text-secondary">INVENTARIOS</h5>
                            </div>
                        </div>
                        </Link>
                    </div>          
            </div>
        </div>
    </div>
  )
}
