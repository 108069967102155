import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

const customStyles = [
    { "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [{"color": "#444444"}]
    },
    { "featureType": "landscape",
      "elementType": "all",
      "stylers": [{"color": "#f2f2f2"}]
    },
    { "featureType": "poi",
      "elementType": "all",
      "stylers": [{"visibility": "off"}]
    },
    { "featureType": "road",
      "elementType": "all",
      "stylers": [{"saturation": -100},
                  {"lightness": 45}]
    },
    { "featureType": "road.highway",
      "elementType": "all",
        "stylers": [{"visibility": "simplified"}]
    },
    { "featureType": "road.arterial",
      "elementType": "labels.icon",
      "stylers": [{"visibility": "off"}]
    },
    { "featureType": "transit",
      "elementType": "all",
      "stylers": [{"visibility": "off"}]
    },
    { "featureType": "water",
      "elementType": "all",
      "stylers": [{"color": "#a4e0e5"},
                  {"visibility": "on"}]
    }
];
export class MapGoogle extends Component {
  constructor(props) {
    super(props);
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      markers:[]
    };
  }
  onMarkerClick(props, marker, e) {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }

  componentDidMount(){
    this.setState({
      markers: [
        {id: this.props.id, position: this.props.position, name: this.props.name}
      ]
    })
  }

  render() {
    const { markers } = this.state
    if (!this.props.google) {
      return <div>Loading...</div>;
    }
    return (
      <div style={{ display: "flex", height: "25rem", width: "100%" }}>
        <Map
          initialCenter={{ lat: this.props.position.lat, lng: this.props.position.lng }}
          google={this.props.google}
          zoom={14}
          styles={customStyles}
          mapTypeControl={false}
          style={{ flexGrow: "1", height: "100%" }}
        >
        {markers.map(({ id, name, position }) => (
          <Marker
            onClick={this.onMarkerClick}
            name={name}
            label={{ text: id, color: "#fff", fontWeight: "bold",}}
            position={position}
            icon= {{
              path: 'M8 2.1c1.1 0 2.2 0.5 3 1.3 0.8 0.9 1.3 1.9 1.3 3.1s-0.5 2.5-1.3 3.3l-3 3.1-3-3.1c-0.8-0.8-1.3-2-1.3-3.3 0-1.2 0.4-2.2 1.3-3.1 0.8-0.8 1.9-1.3 3-1.3z',
              scale: 2.8,
              fillColor: "#1ba9b3",
              fillOpacity: 1,
              strokeWeight: 0,
              labelOrigin: { x: 8, y: 7},
            }}
          />
          ))}
          <InfoWindow marker={this.state.activeMarker} visible={this.state.showingInfoWindow}>
            <div>
              <p>{this.state.selectedPlace.name}</p>
            </div>
          </InfoWindow>
        </Map>
      </div>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyBQfXNN20aLNzswFc_s6V6sluHrmIQuYcA",
  v: "3.30"
})(MapGoogle);