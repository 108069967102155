import React from 'react'
import { Link } from "gatsby"

export default function Commercial(props) {
  const { links } = props
  return (
    <div className="tab-pane">
        <div className="container overflow-hidden p-5">
            <div className="row align-items-stretch g-2">
                <div className="col">
                    <Link 
                    to={links.commercialFlats}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-columns"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">PLANOS COMERCIALES</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.commercialSurfaces}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-border-outer"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">SUPERFICIES COMERCIALES</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.commercialProjectReport}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-clipboard"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">MEMORIA DE PROYECTO</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.commercialCurrentPhase}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-front"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">FASE ACTUAL</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.commercialRenders}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                    <i class="bi bi-box"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">RENDERS</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.commercialAR}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-view-list"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">REALIDAD AUMENTADA</h5>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}