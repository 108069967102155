import React from 'react'
import { Link } from "gatsby"

export default function Bim(props) {
  const { links } = props
  const [visible, setVisible] = React.useState(false);
  return (
    <div className="tab-pane">
        <div className="container overflow-hidden p-5">
            <div className="row align-items-center g-5">
                <div className="col">
                    <Link 
                    to={links.bimCoordination}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                    <i className="bi bi-diagram-2"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">COORDINACIÓN</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.bimArchitecture}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                    <i class="bi bi-columns"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">ARQUITECTURA</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <button onClick={() => setVisible(!visible)} style={{border: 'none', padding: 0, width: '100%',}}>
                    <div className="card p-2">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                    <i class="bi bi-building"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">MEP</h5>
                        </div>
                    </div>
                    </button>
                </div>
                <div className="col">
                    <Link 
                    to={links.bimImplantations}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                    <i class="bi bi-sliders2-vertical"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">IMPLANTACIONES</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.bimCad}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                    <i class="bi bi-front"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">CAD</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="row align-items-stretch g-2 pt-4" style={{display: visible ? 'flex' : 'none'}}>
                    <div className="col">
                        <Link 
                        to={links.bimMepMechanics}
                        target='_blank'
                        className="text-decoration-none"
                        >
                        <div className="card p-2 bg-primary card-h">
                            <div className="card-body">
                                <p className="card-title text-center text-white">MECÁNICA</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col">
                        <Link 
                        to={links.bimMepPlumbing}
                        target='_blank'
                        className="text-decoration-none"
                        >
                        <div className="card p-2 bg-primary card-h">
                            <div className="card-body">
                                <p className="card-title text-center text-white">FONTANERÍA</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col">
                        <Link 
                        to={links.bimMepElectricity}
                        target='_blank'
                        className="text-decoration-none"
                        >
                        <div className="card p-2 bg-primary card-h">
                            <div className="card-body">
                                <p className="card-title text-center text-white">ELECTRICIDAD</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col">
                        <Link 
                        to={links.bimMepPci}
                        target='_blank'
                        className="text-decoration-none"
                        >
                        <div className="card p-2 bg-primary card-h">
                            <div className="card-body">
                                <p className="card-title text-center text-white">PCI</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                    <div className="col">
                        <Link 
                        to={links.bimMepBms}
                        target='_blank'
                        className="text-decoration-none"
                        >
                        <div className="card p-2 bg-primary card-h">
                            <div className="card-body">
                                <p className="card-title text-center text-white">BMS</p>
                            </div>
                        </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
