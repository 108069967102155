import React from 'react'
import { Link } from "gatsby"

export default function Area(props) {
  const { links } = props
  return (
    <div className="tab-pane">
        <div className="container overflow-hidden p-5">
            <div className="row align-items-center g-5">
                <div className="col">
                    <Link 
                    to={links.areaAeo}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">SUPERFICIE AEO</h5>
                        </div>
                    </div>
                    </Link>
                </div>   
                <div className="col">
                    <Link 
                    to={links.areaTableAeo}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">TABLA AEO</h5>
                        </div>
                    </div>
                    </Link>
                </div>   
                <div className="col">
                    <Link 
                    to={links.areaSurfaces}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">SUPERFICIES COMERCIALES</h5>
                        </div>
                    </div>
                    </Link>
                </div>           
            </div>
        </div>
    </div>
  )
}
