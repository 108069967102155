import React from 'react'
import { Link } from "gatsby"

export default function RA(props) {
  const { links } = props
  return (
    <div className="tab-pane">
        <div className="container overflow-hidden p-5">
            <div className="row align-items-stretch g-2">
            <div className="col">
                    <Link 
                    to={links.tracingScan}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-hr"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">ESCANEO</h5>
                        </div>
                    </div>
                    </Link>
                </div>      
            </div>
        </div>
    </div>
  )
}
