import React from 'react'
import { Link } from "gatsby"

export default function Doc(props) {
  const { links } = props
  return (
    <div className="tab-pane">
        <div className="container overflow-hidden p-5">
            <div className="row align-items-stretch g-2">
                <div className="col">
                    <Link 
                    to={links.docWorkRecords}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">ACTAS INICIO / FIN DE OBRA</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docWorkCertificate}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">CERTIFICADOS DE OBRA</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docMaintenanceCertificate}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">CERTIFICADOS DE MANTENIMIENTO</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docGeotechnicalControl}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">CONTROL GEOTÉCNICO</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docControlInstallations}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">CONTROL DE INSTALACIONES</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docActivitiesCoordination}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">COORDINACIÓN ACTIVIDADES EMPRESARIALES</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
            
            <div className="row align-items-stretch g-2 pt-4">
                <div className="col">
                    <Link 
                    to={links.docCorrectives}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">CORRECTIVOS</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docGeneral}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">DOCUMENTACIÓN GENERAL</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docAdministrativeLegal}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">DOC. JURÍDICA Y ADMINISTRATIVA</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docRSC}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">DOCUMENTACIÓN RSC</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docPrevious}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">DOCUMENTACIÓN PREVIA</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docRisksEvaluation}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">EVALUACIÓN DE RIESGOS</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>

            <div className="row align-items-stretch g-2 pt-4">
                <div className="col">
                    <Link 
                    to={links.docISOs}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">ISO 14001 / ISO 50001</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docImplementations}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">IMPLANTACIONES</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docOCTReports}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">INFORMES OCT</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docWorkReports}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">INFORMES DE OBRA</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docTechnicalReports}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">INFORMES TÉCNICOS</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docBuildingBook}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">LIBRO DEL EDIFICIO</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        
            <div className="row align-items-stretch g-2 pt-4">
                <div className="col">
                    <Link 
                    to={links.docLicenses}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">LICENCIAS</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docBiddingWorkContracts}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">LICITACIÓN Y CONTRATOS DE OBRA</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docSelfprotectionManuals}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">MANUALES AUTOPROTECCIÓN</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docProjects}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">PROYECTOS</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docProcessingLegalization}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">TRAMITACIÓN Y LEGALIZACIÓN</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.docFlats}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-primary">ÚLTIMA VERSIÓN DE PLANOS</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>

        </div>
    </div>
  )
}