import React from "react"
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/madrid/profile/profile"
import ProfileB from '../components/barcelona/profile/profile'
import ProfileU from '../components/utopicus/profile/profile'
import Login from "./login"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  if (typeof window !== 'undefined') {
    return (
      <Router>
        <Routes>
          <Route path="/app/madrid/profile/:id" element={<PrivateRoute />}>
            <Route path="/app/madrid/profile/:id" element={<Profile />} />
          </Route>
          <Route path="/app/barcelona/profile/:id" element={<PrivateRoute />}>
            <Route path="/app/barcelona/profile/:id" element={<ProfileB />} />
          </Route>
          <Route path="/app/utopicus/profile/:id" element={<PrivateRoute />}>
            <Route path="/app/utopicus/profile/:id" element={<ProfileU />} />
          </Route>
          <Route path="/app/login" element={<Login />} />
        </Routes>
      </Router>
    )
  } else {
    return [];
  }
}

export default App