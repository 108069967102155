import React from 'react'
import { Link } from "gatsby"

export default function Eco(props) {
  const { links } = props
  return (
    <div className="tab-pane">
        <div className="container overflow-hidden p-5">
            <div className="row align-items-center g-5">
                <div className="col">
                    <Link 
                    to={links.ecoCarbonFootprint}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">HUELLA DE CARBONO</h5>
                        </div>
                    </div>
                    </Link>
                </div>   
                <div className="col">
                    <Link 
                    to={links.ecoCertificate}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">CERTIFICADO MEDIOAMBIENTAL</h5>
                        </div>
                    </div>
                    </Link>
                </div> 
                <div className="col">
                    <Link 
                    to={links.ecoProvisioning}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">APROVISIONAMIENTO</h5>
                        </div>
                    </div>
                    </Link>
                </div> 
            </div>
            <div className="row align-items-center g-5 pt-4">
                <div className="col">
                    <Link 
                    to={links.ecoEnergyEfficiency}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">EFICIENCIA ENERGÉTICA</h5>
                        </div>
                    </div>
                    </Link>
                </div> 
                <div className="col">
                    <Link 
                    to={links.ecoWasteManagement}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">GESTIÓN DE RESIDUOS</h5>
                        </div>
                    </div>
                    </Link>
                </div>   
                <div className="col">
                    <Link 
                    to={links.ecoLifeCycle}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h5 className="card-title text-center text-primary">CICLO DE VIDA</h5>
                        </div>
                    </div>
                    </Link>
                </div>                    
            </div>
        </div>
    </div>
  )
}
