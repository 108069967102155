import React from 'react'
import Footer from '../../basic/footer'
import NavProfile from './nav-profile'
import MapGoogle from './map-local'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from "gatsby-background-image"
import Seo from '../../basic/seo'
import { Tabs, Tab } from 'react-bootstrap';
import Bim from './components/bim'
import Doc from './components/documentation'
import Commercial from './components/commercial'
import Stock from './components/stock'
import Eco from './components/eco'
import Area from './components/area'
import Tracing from './components/tracing'
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import madridData from '../data'

export default function Profile() {
  const { id } = useParams();
  const building = madridData.find(o => o.id === id);

  const data = useStaticQuery(
    graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality:90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(building.img_path);
  });
  const imageData = image.node.childImageSharp.fluid;

  const bimLinks = {
    bimCoordination: building.bimCoordination,
    bimArchitecture: building.bimArchitecture,
    bimInstallations: building.bimInstallations,
    bimMepMechanics: building.bimMepMechanics,
    bimMepPlumbing: building.bimMepPlumbing,
    bimMepElectricity: building.bimMepElectricity,
    bimMepPci: building.bimMepPci,
    bimMepBms: building.bimMepBms,
    bimCad: building.bimCad,
    bimImplantations: building.bimImplantations

  }

  const docLinks = {
    docWorkRecords: building.docWorkRecords,
    docWorkCertificate: building.docWorkCertificate,
    docMaintenanceCertificate: building.docMaintenanceCertificate,
    docGeotechnicalControl:  building.docGeotechnicalControl,
    docControlInstallations: building.docControlInstallations,
    docActivitiesCoordination: building.docActivitiesCoordination,
    docCorrectives: building.docCorrectives,
    docGeneral: building.docGeneral,
    docAdministrativeLegal: building.docAdministrativeLegal,
    docRSC: building.docRSC,
    docPrevious: building.docPrevious,
    docRisksEvaluation: building.docRisksEvaluation,
    docISOs: building.docISOs,
    docImplementations: building.docImplementations,
    docOCTReports: building.docOCTReports,
    docWorkReports: building.docWorkReports,
    docTechnicalReports: building.docTechnicalReports,
    docBuildingBook: building.docBuildingBook,
    docLicenses: building.docLicenses,
    docBiddingWorkContracts: building.docBiddingWorkContracts,
    docSelfprotectionManuals: building.docSelfprotectionManuals,
    docProjects: building.docProjects,
    docProcessingLegalization: building.docProcessingLegalization,
    docFlats: building.docFlats,
  }

  const commercialLinks = {
    commercialFlats: building.commercialFlats,
    commercialSurfaces: building.commercialSurfaces,
    commercialProjectReport: building.commercialProjectReport,
    commercialCurrentPhase: building.commercialCurrentPhase,
    commercialRenders: building.commercialRenders,
    commercialAR: building.commercialAR,
  }

  const stockLinks = {
    stockStocks: building.stockStocks,
  }

  const ecoLinks = {
    ecoCarbonFootprint: building.ecoCarbonFootprint,
    ecoCertificate: building.ecoCertificate,
    ecoProvisioning: building.ecoProvisioning,
    ecoEnergyEfficiency: building.ecoEnergyEfficiency,
    ecoWasteManagement: building.ecoWasteManagement,
    ecoLifeCycle: building.ecoLifeCycle,
  }

  const areaLinks = {
    areaAeo: building.areaAeo,
    areaTableAeo: building.areaTableAeo,
    areaSurfaces: building.areaSurfaces,
  }

  const tracingLinks = {
    tracingContract: building.tracingContract,
    tracingCost: building.tracingCost,
    tracingTerm: building.tracingTerm,
    tracingBudgets: building.tracingBudgets,
    tracingCurrentPhase: building.tracingCurrentPhase,
    tracingScan: building.tracingScan,
    buildingMemoryQuality: building.buildingMemoryQuality,
    buildingCFO: building.buildingCFO,
    buildingWorkRecords: building.buildingWorkRecords,
    buildingCadastralReference: building.buildingCadastralReference,
    buildingLicense: building.buildingLicense,
    buildingASBuilt: building.buildingASBuilt,
    buildingWellnessCertificate: building.buildingWellnessCertificate,
    buildingPECOC: building.buildingPECOC,
    buildingLegalizations: building.buildingLegalizations,
    buildingCompaniesInvolved: building.buildingCompaniesInvolved,
    buildingBuildingBook: building.buildingBuildingBook,
    buildingPlanning: building.buildingPlanning,
    buildingFlats: building.buildingFlats,
  }


  return (
    <div className="container-fluid" style={{ backgroundColor: '#edf0f0', padding: '0' }}>
      <Seo title="Profile" />
      <div className="container-fluid">
        <NavProfile />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6" style={{ margin: '0', padding: '0' }}>
            <BackgroundImage
              Tag="section"
              className="row align-items-end"
              fluid={imageData}
              style={{ width: '100%', height: '25rem', backgroundSize: 'cover', margin: '0', padding: '0' }}
            >
              <div className="col w-100 p-3" style={{ backgroundColor: 'rgba(20, 131, 139, 0.8)' }}>
                <h4 className="text-light ms-3 fw-bold">{building.name}</h4>
                <p className="text-light ms-3">{building.address}</p>
              </div>
            </BackgroundImage>
          </div>
          <div className="col-md-6" style={{ position: "relative", margin: '0', padding: '0' }}>
            <MapGoogle id={building.id} name={building.name} position={building.position.lat, building.position} />
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <div>
        <Tabs defaultActiveKey="bim" id="uncontrolled-tab-example" className="bg-light p-4 nav justify-content-center nav-pills">
          <Tab eventKey="bim" title="BIM">
            <Bim links={bimLinks} />
          </Tab>
          <Tab eventKey="profile" title="DOCUMENTACIÓN">
            <Doc links={docLinks} />
          </Tab>
          <Tab eventKey="commercial" title="COMERCIAL">
            <Commercial links={commercialLinks} />
          </Tab>
          <Tab eventKey="stock" title="INVENTARIOS">
            <Stock links={stockLinks} />
          </Tab>
          <Tab eventKey="eco" title="SOSTENIBILIDAD">
            <Eco links={ecoLinks} />
          </Tab>
          <Tab eventKey="area" title="SUPERFICIES">
            <Area links={areaLinks} />
          </Tab>
          <Tab eventKey="tracing" title="SEGUIMIENTO DE OBRA">
            <Tracing links={tracingLinks} />
          </Tab>
        </Tabs>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}