import React from "react"
import { isLoggedIn } from "../services/auth"
import { Outlet, Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (typeof window !== 'undefined'){
    if (!isLoggedIn()) {
      return <Navigate to="/app/login/" />
    }
    return <Outlet />
  }
}

export default PrivateRoute