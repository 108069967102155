import React from 'react'
import Footer from '../../basic/footer'
import NavProfile from './nav-profile'
import MapGoogle from './map-local'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from "gatsby-background-image"
import Seo from '../../basic/seo'
import { Tabs, Tab } from 'react-bootstrap';
import Bim from './components/bim'
import Doc from './components/documentation'
import Commercial from './components/commercial'
import RA from './components/ra'
import { BrowserRouter as Router, Switch, Route, Link, useParams } from "react-router-dom";
import utopicusData from '../data'

export default function ProfileU() {
  const { id } = useParams();
  const building = utopicusData.find(o => o.id === id);

  const data = useStaticQuery(
    graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(quality:90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(building.img_path);
  });
  const imageData = image.node.childImageSharp.fluid;

  const bimLinks = {
    bimCoordination: building.bimCoordination,
    bimArchitecture: building.bimArchitecture,
    bimMepMechanics: building.bimMepMechanics,
    bimMepPlumbing: building.bimMepPlumbing,
    bimMepElectricity: building.bimMepElectricity,
    bimMepPci: building.bimMepPci,
    bimMepBms: building.bimMepBms,
    bimCad: building.bimCad,
    bimInstallations: building.bimInstallations
  }

  const docLinks = {
    commercialFlats: building.commercialFlats
  }

  const commercialLinks = {
    commercialRenders: building.commercialRenders
  }

  const RALinks = {
    tracingScan: building.tracingScan
  }


  return (
    <div className="container-fluid" style={{ backgroundColor: '#edf0f0', padding: '0' }}>
      <Seo title="Profile" />
      <div className="container-fluid">
        <NavProfile />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6" style={{ margin: '0', padding: '0' }}>
            <BackgroundImage
              Tag="section"
              className="row align-items-end"
              fluid={imageData}
              style={{ width: '100%', height: '25rem', backgroundSize: 'cover', margin: '0', padding: '0' }}
            >
              <div className="col w-100 p-3" style={{ backgroundColor: 'rgba(20, 131, 139, 0.8)' }}>
                <h4 className="text-light ms-3 fw-bold">{building.name}</h4>
                <p className="text-light ms-3">{building.address}</p>
              </div>
            </BackgroundImage>
          </div>
          <div className="col-md-6" style={{ position: "relative", margin: '0', padding: '0' }}>
            <MapGoogle id={building.id} name={building.name} position={building.position.lat, building.position} />
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <div>
        <Tabs defaultActiveKey="bim" id="uncontrolled-tab-example" className="bg-light p-4 nav justify-content-center nav-pills">
          <Tab eventKey="bim" title="BIM">
            <Bim links={bimLinks} />
          </Tab>
          <Tab eventKey="commercial" title="COMERCIAL">
            <Commercial links={commercialLinks} />
          </Tab>
          <Tab eventKey="profile" title="DOCUMENTACIÓN">
            <Doc links={docLinks} />
          </Tab>
          <Tab eventKey="RA" title="REALIDAD AUMENTADA">
            <RA links={RALinks} />
          </Tab>
        </Tabs>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}