import React from 'react'
import { Link } from "gatsby"

export default function Tracing(props) {
  const { links } = props;
  const [visible, setVisible] = React.useState(false);
  return (
    <div className="tab-pane">
        <div className="container overflow-hidden p-5">
            <div className="row align-items-stretch g-2">
                <div className="col">
                    <Link 
                    to={links.tracingContract}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-file-medical"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">CONTRATO</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.tracingCost}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-cash-coin"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">COSTE</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.tracingTerm}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-calendar-check-fill"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">PLAZO</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <button onClick={() => setVisible(!visible)} style={{border: 'none', padding: 0,}}>
                        <div className="card p-2 card-h">
                            <div className="card-body">
                                <h2 className='text-center p-2'>
                                    <span className="badge badge-profile bg-primary rounded-circle">
                                    <i class="bi bi-calendar-check-fill"></i>
                                    </span>
                                </h2>
                                <h5 className="card-title text-center text-secondary">DOCUMENTACIÓN EDIFICIO</h5>
                            </div>
                        </div>
                    </button>
                </div>
                <div className="col">
                    <Link 
                    to={links.tracingBudgets}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-calculator"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">PRESUPUESTOS</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.tracingCurrentPhase}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-front"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">FASE ACTUAL</h5>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.tracingScan}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 card-h">
                        <div className="card-body">
                            <h2 className='text-center p-2'>
                                <span className="badge badge-profile bg-primary rounded-circle">
                                <i class="bi bi-hr"></i>
                                </span>
                            </h2>
                            <h5 className="card-title text-center text-secondary">ESCANEO</h5>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>

            <div className="row align-items-stretch g-2 pt-4" style={{display: visible ? 'flex' : 'none'}}>
                <div className="col">
                    <Link 
                    to={links.buildingMemoryQuality}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">MEMORIA DE CALIDADES</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingCFO}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">CFO</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingWorkRecords}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">ACTAS DE OBRA</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingCadastralReference}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">REFERENCIA CATASTRAL</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingLicense}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">LICENCIAS</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingASBuilt}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">DOCUMENTACIÓN AS BUILT</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingWellnessCertificate}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">CERTIFICACIÓN WELLNESS</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingPECOC}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">PRESUPUESTOS PEC + OC</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingLegalizations}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">LEGALIZACIONES</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingCompaniesInvolved}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">DATOS EMPRESAS INTERVINIENTES</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingBuildingBook}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">LIBRO DEL EDIFICIO</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingPlanning}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">PLANIFICACIÓN</p>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className="col">
                    <Link 
                    to={links.buildingFlats}
                    target='_blank'
                    className="text-decoration-none"
                    >
                    <div className="card p-2 bg-primary card-h">
                        <div className="card-body">
                            <p className="card-title text-center text-white">PLANOS</p>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>


        </div>
    </div>
  )
}